import { useState } from "react";
import { Col, Container, Row, Button, ListGroup, Card } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import PUBGM from "../assets/images/icons/pubgmIcon.png";
import FreeFire from "../assets/images/icons/freefireIcon.jpg";
import MobileLegend from "../assets/images/icons/mobilelegendIcon.jpg";
import FooterBar from "./FooterBar";

function Intro() {
  const [open, setOpen] = useState(false);

  return (
    <div className="intro" id="intro">
      <Container className="text-light d-flex justify-content-center align-items-center text-center">
        <Row>
          <Col>
            <div className="title">IMAM</div>
            <div className="title">WIKIPEDIA GAMES</div>
            <div className="introButton mt-4 text-center">
              <Button
                variant="success"
                onClick={() => setOpen(!open)}
                aria-controls="example-fade-text"
                aria-expanded={open}
              >
                Lihat Semua Game
              </Button>
              <div style={{ minHeight: "150px" }}>
                <CSSTransition
                  style={{
                    border: "1px solid rgba(255,255,255,1.0)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.38)",
                    fontFamily: "monospace",
                    textShadow: "2px 2px 4px #000000",
                  }}
                  in={open}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <div id="example-fade-text">
                    <div className="mt-2 text-center">
                      <ListGroup.Item>
                        <Button
                          href="/MobileLegend"
                          variant="primary"
                          className="w-50 mb-1 text-start"
                        >
                          <img
                            src={MobileLegend}
                            className="me-2"
                            style={{ width: "25px", height: "25px" }}
                          />
                          Mobile Legend
                        </Button>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Button
                          href="/PUBGMobile"
                          variant="primary"
                          className="w-50 mb-1 text-start"
                        >
                          <img
                            src={PUBGM}
                            className="me-2"
                            style={{ width: "25px", height: "25px" }}
                          />
                          PUBG Mobile
                        </Button>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Button
                          href="/FreeFire"
                          variant="primary"
                          className="w-50 mb-1 text-start"
                        >
                          <img
                            src={FreeFire}
                            className="me-2"
                            style={{ width: "25px", height: "25px" }}
                          />
                          Free Fire
                        </Button>
                      </ListGroup.Item>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Intro;
