import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import NavigationBar from "../NavigationBar";

const UserDashboard = () => {
  const [userAccount, setUserAccount] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm");
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      // Fetch the user account details from the API (ensure you use the correct endpoint for user data)
      const fetchUserAccount = async () => {
        try {
          const token = localStorage.getItem("authToken");
          const response = await fetch(
            "https://wikipediagames.wikipediagames.com/api/user-account",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setUserAccount(data);

            // Redirect to AdminDashboard if user role is 'admin'
            if (data.role === "admin") {
              alert(
                "You are logged in as an Admin. Redirecting to Admin Dashboard..."
              );
              navigate("/AdminDashboard");
            } else if (data.role !== "user") {
              alert("Unauthorized access. Please log in as a user.");
              navigate("/LoginForm");
            }
          } else {
            console.error(
              "Failed to fetch user account details:",
              await response.text()
            );
            setIsAuthenticated(false);
            navigate("/LoginForm");
          }
        } catch (error) {
          console.error("Failed to fetch user account details:", error);
          setIsAuthenticated(false);
          navigate("/LoginForm");
        }
      };

      fetchUserAccount();
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        "https://wikipediagames.wikipediagames.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      localStorage.removeItem("authToken");
      window.location.href = "/LoginForm";
    } catch (error) {
      alert("Error during logout!");
      console.error("There was an error during logout!", error);
    }
  };

  return (
    <div className="page-wrapper">
      <NavigationBar />
      <Container fluid className="content p-3">
        <br />
        <br />
        <h1 className="text-center text-light mt-2">
          <b>User Dashboard</b>
        </h1>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} lg={6} className="mb-4">
            <Card
              className="shadow-sm d-flex justify-content-center align-items-center text-white"
              style={{
                backgroundColor: "rgba(200, 200, 200, 0.3)",
                border: "1px solid rgba(255,255,255,1.0)",
              }}
            >
              <Card.Title className="text-center">
                <b>User Account Details</b>
              </Card.Title>
              <Card.Body
                style={{ maxWidth: "750px", fontFamily: "TimesnewRoman" }}
              >
                {userAccount ? (
                  <Card.Text>
                    <Row>
                      <Col className="text-center">
                        <Image
                          src={
                            userAccount.displayPicture ||
                            "https://via.placeholder.com/50"
                          }
                          className="me-1"
                          width={125}
                          height={125}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div className="mx-auto">
                          <b>Username:</b> {userAccount.username} <br />
                          <b>Name:</b> {userAccount.name} <br />
                          <b>Role:</b> {userAccount.role}
                        </div>
                      </Col>
                      <div className="mt-2 text-center">
                        <Row>
                          <Col>
                            <Button
                              variant="info"
                              onClick={handleLogout}
                              className=""
                            >
                              <i className="fas fa-image"></i>
                            </Button>
                          </Col>
                          <Col className="d-flex align-items-center">
                            <Button variant="success" onClick={handleLogout}>
                              Setting
                            </Button>
                            <Button
                              variant="danger"
                              onClick={handleLogout}
                              className="ms-2"
                            >
                              Logout
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </Card.Text>
                ) : (
                  <Card.Text>Loading user account details...</Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserDashboard;
