import { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../Api"; // Ensure axios instance is correctly configured
import "../../style/content.css";

function Select() {
  const navigate = useNavigate();
  const [account, setAccount] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Default to false

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");

      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm");
        return;
      }

      try {
        // Check authentication
        const authResponse = await axios.get("/admin-account", {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Check if the user is an admin
        if (authResponse.data.role !== "admin") {
          navigate("/UserDashboard");
          return;
        }

        setIsAuthenticated(true);

        // Fetch account data
        const dataResponse = await axios.get("/get_data", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setAccount(dataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/LoginForm");
      }
    };

    fetchData();
  }, [navigate]);

  const editAccount = async (id) => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.put(
        `/edit/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert("Account edited successfully!");
    } catch (error) {
      alert("Error during editing!");
      console.error("Error during editing:", error);
    }
  };

  const deleteAccount = async (id) => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.delete(`/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccount((prevAccounts) => prevAccounts.filter((acc) => acc.id !== id));
      alert("Account deleted successfully!");
    } catch (error) {
      alert("Error during deletion!");
      console.error("Error during deletion:", error);
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10}>
          <Card className="shadow card" style={{ maxHeight: "100vh" }}>
            <Card.Body>
              <Card.Title className="text-center mb-4">
                <b>ALL ACCOUNT WIKIPEDIA GAMES</b>
              </Card.Title>
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Action</th>
                    <th>Gender</th>
                    <th>Born Place</th>
                    <th>Birth Date</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {account.map((bk) => (
                    <tr key={bk.id}>
                      <td>{bk.id}</td>
                      <td>
                        {bk.username}
                        <br />
                        as : ({bk.role})
                      </td>
                      <td>{bk.name}</td>
                      <td>
                        <Form>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => editAccount(bk.id)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger mt-1"
                            type="button"
                            onClick={() => deleteAccount(bk.id)}
                          >
                            Delete
                          </button>
                        </Form>
                      </td>
                      <td>{bk.gender}</td>
                      <td>{bk.bornplace}</td>
                      <td>{bk.birthdate}</td>
                      <td>{bk.phonenumber}</td>
                      <td>{bk.address}</td>
                      <td>
                        <Card.Text className="text-left bg-success text-white">
                          Created at :{" "}
                          {(() => {
                            const date = new Date(bk.created_at);
                            const time = date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            });
                            const formattedDate = date.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            );
                            return `${time} (${formattedDate})`;
                          })()}
                        </Card.Text>
                        <Card.Text className="text-left bg-black text-white">
                          Updated at :{" "}
                          {(() => {
                            const date = new Date(bk.updated_at);
                            const time = date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            });
                            const formattedDate = date.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            );
                            return `${time} (${formattedDate})`;
                          })()}
                        </Card.Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Col className="d-flex justify-content-center mt-4">
            <Button className="btn btn-primary" href="/AdminDashboard">
              Back to Dashboard
            </Button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Select;
