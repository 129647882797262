import React from "react";
import PropTypes from "prop-types";

const ImageStorage = ({ imagePath }) => {
  const baseURL = "https://wikipediagames.wikipediagames.com/storage/";

  return <img src={`${baseURL}${imagePath}`} alt="Card" className="images" />;
};

ImageStorage.propTypes = {
  imagePath: PropTypes.string.isRequired,
};

export default ImageStorage;
