import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// =========================GENERAL COMPONENTS===============================================
import Menu from "./components/components_card/Menu";
import Intro from "./components/Intro";
import NavigationBar from "./components/NavigationBar";

// =========================ADMIN COMPONENTS==================================================
import AdminDashboard from "./components/components_dashboardAdmin/AdminDashboard";
import AccountData from "./components/components_dashboardAdmin/AccountData";
import AdminCommentData from "./components/components_dashboardAdmin/AdminCommentData";
import AdminContentDashboard from "./components/components_dashboardAdmin/AdminContentDashboard";
import MobileLegendAdmin from "./components/components_mobile_legend/MobileLegendAdmin";
import PUBGMobileAdmin from "./components/components_pubgm/PUBGMobileAdmin";
import FreeFireAdmin from "./components/components_freefire/FreeFireAdmin"; // Untuk Admin

// =========================USER COMPONENTS================================================
import UserDashboard from "./components/components_dashboardUser/UserDashboard";
import MobileLegend from "./components/components_mobile_legend/MobileLegend";
import PUBGMobile from "./components/components_pubgm/PUBGMobile";
import FreeFire from "./components/components_freefire/FreeFire";

// =========================LOGIN COMPONENTS================================================
import FooterBar from "./components/FooterBar";
import LoginForm from "./components/components_login/LoginForm";
import RegisterForm from "./components/components_login/RegisterForm";

// =========================CSS COMPONENTS================================================
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/landingPage.css";
// import "./App.css";

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <main className="flex-fill">
          <Routes>
            <Route
              path="/"
              element={
                <div className="myBG">
                  <div className="d-flex flex-column min-vh-100">
                    <main className="flex-fill">
                      <NavigationBar />
                      <Intro />
                    </main>
                    <div className="margin-intro-footer">
                      <FooterBar />
                    </div>
                  </div>
                </div>
              }
            />
            {/* tambahkan kondisi untuk menampilkan halaman kusus admin dan user untuk selain masuk ke admin dashboard */}
            <Route
              path="/AdminDashboard"
              element={
                <div className="myBG">
                  {/* <NavigationBar /> */}
                  <AdminDashboard />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/AdminContentDashboard"
              element={
                <div className="myBG">
                  {/* <NavigationBar /> */}
                  <AdminContentDashboard />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/AdminCommentsData"
              element={
                <div className="myBG">
                  <div className="d-flex flex-column min-vh-100">
                    <main className="flex-fill">
                      <NavigationBar />
                      <AdminCommentData />
                    </main>
                    <FooterBar />
                  </div>
                </div>
              }
            />
            <Route
              path="/UserDashboard"
              element={
                <div className="myBG">
                  {/* <NavigationBar /> */}
                  <UserDashboard />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/AccountData"
              element={
                <div className="accountdata">
                  <NavigationBar />
                  <AccountData />
                  <div className="d-flex flex-column min-vh-100">
                    <main className="flex-fill"></main>
                    <FooterBar />
                  </div>
                </div>
              }
            />
            <Route
              path="/MobileLegend"
              element={
                <div className="mobilelegend">
                  <NavigationBar />
                  <Intro />
                  <MobileLegend />

                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/MobileLegendAdmin"
              element={
                <div className="mobilelegend">
                  <NavigationBar />
                  <MobileLegendAdmin />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/PUBGMobile"
              element={
                <div className="pubgm">
                  <NavigationBar />
                  <Intro />
                  <PUBGMobile />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/PUBGMobileAdmin"
              element={
                <div className="pubgmAdmin">
                  <NavigationBar />
                  <PUBGMobileAdmin />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/FreeFire"
              element={
                <div className="freefire">
                  <NavigationBar />
                  <Intro />
                  <FreeFire />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/FreeFireAdmin"
              element={
                <div className="freefire">
                  <NavigationBar />
                  <FreeFireAdmin />
                  <FooterBar />
                </div>
              }
            />
            <Route path="/contact" element={<FooterBar />} />
            <Route path="/about" element={<FooterBar />} />
            <Route
              path="/LoginForm"
              element={
                <div className="loginform">
                  <NavigationBar />
                  <LoginForm />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/RegisterForm"
              element={
                <div className="registerform">
                  <NavigationBar />
                  <RegisterForm />
                  <FooterBar />
                </div>
              }
            />
            <Route
              path="/Menu"
              element={
                <div className="menu">
                  <NavigationBar />
                  <Menu />
                  <FooterBar />
                </div>
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
