import { Container, Navbar, Form, Button, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import axios from "./Api"; // Use Axios for API requests
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure Font Awesome CSS is imported
import "../style/footerBar.css"; // Import the custom CSS

const FooterBar = () => {
  // State to hold form input values
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create a data object with the form values
    const formData = { name, comment };

    try {
      // Make a POST request to the API endpoint using Axios
      const response = await axios.post("/comments", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        // Handle successful response
        console.log("Comment submitted successfully");
        setName(""); // Clear the form fields after submission
        setComment("");
      } else {
        // Handle errors
        console.error("Failed to submit comment");
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  return (
    <Navbar
      bg="dark"
      className="text-center text-white fixed-bottom custom-footer"
    >
      <Container className="p-4 d-flex flex-column align-items-center justify-content-between h-100">
        <div>
          <section className="mb-4">
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/Imammfauji/"
              role="button"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/_imamfs/"
              role="button"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.linkedin.com/in/imamfauji/"
              role="button"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.youtube.com/c/imamf"
              role="button"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://github.com/ImamFauji"
              role="button"
            >
              <i className="fab fa-github"></i>
            </a>
          </section>
        </div>
        <Form className="mb-4 w-100" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formComment">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="outline-light" type="submit">
            Submit
          </Button>
        </Form>
        <div className="text-center copyright-text">
          © 2024 Wikipedia Games, All rights reserved.
        </div>
      </Container>
    </Navbar>
  );
};

export default FooterBar;
